<template>
  <div
    class="loginpages"
    v-loading="loading"
    element-loading-text="修改中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- 标题 -->

    <div
      class="wrap"
      :style="{ transform: 'scale(' + windowWidth / 1920 + ')' }"
    >
      <div class="lgcontent">
        <div class="logincontent">
          <el-form
            :model="loginForm"
            :rules="rules"
            ref="loginForm"
            class="loginContainer"
          >
            <el-form-item class="oldpwd" prop="oldpwd">
              <div class="inputbox">
                <el-input
                  v-model="loginForm.oldpwd"
                  placeholder="请输入旧密码"
                  clearable
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item class="password" prop="password">
              <div class="inputbox">
                <el-input
                  v-model="loginForm.password"
                  placeholder="请输入新密码"
                  show-password
                ></el-input>
              </div>
            </el-form-item>

            <el-form-item prop="oldpwd">
              <div class="loginsubmit" @click="loginSubmit">立即修改</div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      loading: false,
      windowWidth: "",
      /* 账户密码登录表单 */
      loginForm: {
        oldpwd: "",
        password: "",
      },
      rules: {
        oldpwd: [
          {
            required: true,
            message: "请输入旧密码",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
      },
    };
  },

  watch: {},
  created() {
    this.windowWidth = window.innerWidth;
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  mounted() {},

  methods: {
    loginSubmit() {
      if (this.loginForm.oldpwd == "" || this.loginForm.password == "") {
        this.$message.error("旧密码或新密码不能为空");
      } else {
        this.loading = true;
        this.$request({
          url: "/api/User/ChangePassword",
          method: "POST",
          widthCredentials: true,
          data: {
            oldpwd: this.loginForm.oldpwd,
            pwd: this.loginForm.password,
            vcode: "",
            uid: this.$store.state.uid,
          },
        })
          .then((res) => {
            if (res.data.status) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              setTimeout((res) => {
                this.$router.push("/newslist");
              }, 1500);
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.loginpages {
  box-sizing: border-box;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  width: 100%;
}

h3 > span {
  font-size: 62px;
}
h3 > span:nth-of-type(1) {
  color: #4e6ef2;
}
h3 > span:nth-of-type(2) {
  color: #d63325;
}
h3 > span:nth-of-type(3) {
  color: #f2b505;
}
h3 > span:nth-of-type(4) {
  color: #4e6ef2;
}
.lgcontent {
  position: relative;
  width: 680px;
}
.lgcontent > .logres {
  margin: auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login,
.register {
  padding: 0 10px;
  font-size: 28px;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  letter-spacing: 0px;
  color: #979797;
  vertical-align: middle;
  cursor: pointer;
}
.activelr {
  position: relative;
  font-size: 36px;
  color: #4e6ef2;
}
.activelr::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 4px;
  border-radius: 4px;
}

.loginContainer {
  margin: auto;
  width: 460px;
}
.loginContainer > .oldpwd,
.loginContainer > .password {
  margin: 0 auto 48px;
  width: 460px;
  background: #ffffff;
  box-sizing: border-box;
}

.loginsubmit {
  margin: 28px auto 0;
  width: 460px;
  font-size: 26px;
  background: #4e6ef2;
  color: #fff;
  height: 54px;
  line-height: 54px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}
.loginsubmit:hover {
  background: #3f63f3;
}

.inputbox >>> .el-input__inner {
  height: 54px;
  line-height: 54px;
  font-size: 20px;
}
</style>

